import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хэрэглэгчийн бүртгэл мэдээлэл харах, засах",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-28T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 4
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хэрэглэгчийн бүртгэл мэдээлэл харах, засах`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Баруун дээд булан дахь хэрэглэгчийн нэрийг дарж хэрэглэгчийн тохиргоо хэсэгт очно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Profile Settings]`}</strong>{` - хэсэгт дарж хэрэглэгч өөрийн бүртгэлтэй мэдээллийг харах, засварлах боломжтой. Энэ хэсэгт дараах мэдээлэл багтана:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Бүртгэлтэй и-мэйл хаяг`}</li>
          <li parentName="ul">{`Байгууллагын нэр`}</li>
          <li parentName="ul">{`Хэрэглэгчийн үүрэг, хандах эрхийн төвшин`}</li>
          <li parentName="ul">{`Овог, нэр
(өөрчлөх бол `}<strong parentName="li">{`[Reset]`}</strong>{` товчийг дарж шинээр овог нэрээ оруулан `}<strong parentName="li">{`[Submit]`}</strong>{` товч дарна)`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      